import React from 'react';

import { CheatCodesModal, Header, Layout, VR } from '@components';

const VRPage = () => (
  <Layout classBackground="max-height">
    <nav aria-label="Main navigation" className="main-nav" role="navigation">
      <Header displayCheatCodes />
      <a
        aria-hidden
        href="https://mastodon.social/@radiumtree"
        rel="me"
        style={{ display: 'none' }}
      >
        Mastodon
      </a>
    </nav>
    <main role="main">
      <VR />
    </main>
    <CheatCodesModal />
  </Layout>
);

export default VRPage;
